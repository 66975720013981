import React, { Component } from 'react';
import { View } from 'react-native';
import { memoize } from 'lodash';
import PropTypes from 'prop-types';
import styles from './styles';
import Banner from '../../../containers/banner/Banner';
import FeatureCard from '../../feature/FeatureCard';
import { LAYOUT, BANNER_TIMEOUT } from '../../../config/Constants';
import Utility from '../../../utils/Utility';
import Offer from '../../../containers/offer/Offer';
import TallBanner from '../../tall-banner/TallBanner';
import RatingCard from '../../testimonialCard/RatingCard';
import EdgeToEdgeBanner from '../../feature/EdgeToEdgeBanner';
import ErrorBoundaryComponent from '../../shared/ErrorBoundaryComponent';
import { isDesktop, isWebMobile } from '../../../utils/BooleanUtility';
import Bullets from '../../shared/Bullets';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

class Pager extends Component {
  static Components = {
    banner: Banner,
    offer: Offer,
    feature: FeatureCard,
    tall_banner: TallBanner,
    rating: RatingCard,
    edge_to_edge_banner: EdgeToEdgeBanner,
  };

  static getListComponent = (display) => {
    return Pager.Components[display];
  };

  static getComponentHeight(item) {
    const { objects = [] } = item;
    try {
      return this.getListComponent(objects[0]?.display).getComponentHeight(objects[0]);
    } catch (error) {
      return styles.pageContainer.height + styles.pageContainer.marginVertical * 2;
    }
  }

  autoplay = true;

  getComponent = (item, index) => {
    if (Utility.isBlank(item)) {
      return null;
    }
    const {
      id,
      navigation,
      allVideosId,
      previousScreen,
      listData,
      listData: { slug: listSlug = '' } = {},
      listName,
      listContent,
      parentListsData = [],
      extraEventParameters = {},
    } = this.props;
    const ContainerComponent = Pager.Components[item.type];
    if (item === undefined || ContainerComponent === undefined) {
      return null;
    }
    return (
      <ErrorBoundaryComponent itemData={item} listData={listData} screenName={previousScreen} key={item.id}>
        <ContainerComponent
          key={item.id}
          id={item.id}
          layout={LAYOUT.PAGER}
          itemData={item}
          type={item.type}
          navigation={navigation}
          listId={id}
          index={index}
          listData={listData}
          listName={listName}
          listSlug={listSlug}
          listContent={listContent}
          listIndex={this.props.index}
          parentListsData={parentListsData}
          allVideosId={allVideosId}
          previousScreen={previousScreen}
          refreshOfferStrip={this.props.refreshOfferStrip}
          extraEventParameters={extraEventParameters}
        />
      </ErrorBoundaryComponent>
    );
  };

  saveSwiperRef = (ref) => {
    this.swiperRef = ref;
  };

  saveBulletsRef = (ref) => {
    this.bulletsRef = ref;
  };

  onIndexChanged = (index) => {
    if (this.bulletsRef) {
      this.bulletsRef.setActiveIndex(index);
    }
  };

  onViewableItemsChangedWeb = ({ activeIndex }) => {
    if (this.bulletsRef) {
      this.bulletsRef.setActiveIndex(activeIndex);
    }
  };

  inactiveDot = () => {
    const { display, listData: { objects = [] } = {} } = this.props;
    const dotStyle = objects[0]?.type === 'feature' ? styles.dotStyleFeature : styles.dotStyle;

    const dotComponentStyle = display === 'pagerBottom' ? styles.dotStyleBottomPager : dotStyle;
    return <View style={dotComponentStyle} />;
  };

  activeDot = () => {
    const { display, listData: { objects = [] } = {} } = this.props;
    const activeDotStyle = objects[0]?.type === 'feature' ? styles.activeDotStyleFeature : styles.activeDotStyle;
    if (display !== 'pagerBottom') return <View style={activeDotStyle} />;
    return <View style={styles.dotStyleBottom} />;
  };
  render() {
    const { display, content, item: itemsArray = [] } = this.props;
    if (Utility.isBlank(itemsArray)) {
      return null;
    }
    if (itemsArray.length === 0) {
      this.autoplay = false;
    }
    const { listData: { objects = [] } = {} } = this.props;

    let imageSize = {};
    if (Utility.isPresent(objects)) {
      const { type } = objects[0];
      //TODO: This is only works for Feature cards of the type LargeBanner
      if (type === 'feature') {
        imageSize = [styles.pageContainerFeature, isDesktop() && styles.pageContainerFeatureDesktop];
        if (objects[0]?.display === 'postcard') {
          imageSize = styles.pagerBottomContainerFeature;
        }
        if (objects[0]?.display === 'edge_to_edge_banner') {
          imageSize = [...imageSize, { height: EdgeToEdgeBanner.getComponentHeight(objects[0]) }];
        }
        if (objects[0]?.display === 'overflow') {
          imageSize = styles.pagerBottomContainerOverFlow;
        }
      } else if (type === 'tall_banner') {
        imageSize = styles.pageContainerTallBanner;
      } else if (objects[0]?.display === 'large_banner') {
        imageSize = [styles.pageContainerFeature, { height: (Utility.getScreenWidth() - 24) / 1.25 }];
      } else if (objects[0]?.type === 'rating') {
        imageSize = styles.pageContainerRating;
      } else {
        imageSize = styles.pageContainer;
      }
    }

    const showsPagination = content !== 'rating';

    const paginationStyle = display === 'pagerBottom' ? bottomPaginationStyle : sidePaginationStyle;

    if (itemsArray.length === 1) {
      return <View style={singleItemContainer}>{this.getComponent(itemsArray[0], 0)}</View>;
    }

    const pagerAutoPlay = this.autoplay
      ? {
          delay: BANNER_TIMEOUT * 1000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }
      : false;

    return (
      <View style={imageSize}>
        <Swiper
          direction='horizontal'
          initialSlide={0}
          slidesPerView={1}
          autoplay={pagerAutoPlay}
          modules={[Autoplay, Pagination, Navigation]}
          onSlideChange={this.onViewableItemsChangedWeb}
        >
          {itemsArray.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <View
                  style={{
                    width: '100%',
                    alignSelf: 'start',
                  }}
                >
                  {this.getComponent(item, index)}
                </View>
              </SwiperSlide>
            );
          })}
        </Swiper>
        {showsPagination && (
          <Bullets
            onRef={this.saveBulletsRef}
            items={itemsArray}
            activeDotView={this.activeDot}
            inactiveDotView={this.inactiveDot}
            containerStyle={paginationStyle}
          />
        )}
      </View>
    );
  }
}
Pager.propTypes = {
  list: PropTypes.shape({
    objects: PropTypes.array,
  }),
};
export default Pager;

const sidePaginationStyle = {
  position: 'absolute',
  left: null,
  right: 20,
  top: 8,
  bottom: null,
  zIndex: 2,
};

const bottomPaginationStyle = {
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
};

const singleItemContainer = {
  ...(isWebMobile() ? { marginLeft: 6 } : {}),

};
