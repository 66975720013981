import React, { useRef } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Clipboard,
  TouchableOpacity,
} from 'react-native';
import { useSelector } from 'react-redux';
import { REMOTE_CONFIG_KEYS, WEB_URL } from '../../config/Constants';
import Config from '../../libraries/ReactNativeConfig';
import colors from '../../theme/Colors';
import { getMinifiedImage } from '../../utils/ImageUtility';
import images from '../../theme/Images';
import { AnalyticsManager, EventParameterKey, EventType } from '../../analytics';
import { isDesktop, isWeb } from '../../utils/BooleanUtility';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { useNavigation } from '@react-navigation/native';
import { useEffect } from 'react';
import Share from '../../libraries/ReactNativeShare';
import RemoteConfig from '../../utils/RemoteConfig';
import { jsonParser } from '../../utils/JsonUtility';
import Toast from 'react-native-easy-toast';

const DermatProfileEditCard = (props) => {
  const navigation = useNavigation();
  const toastRef = useRef(null);
  const invitationCode =
    useSelector((state) => state.UserAccountInfo.invitationCode) || '';
  const handle =
    useSelector((state) => state.UserAccountInfo.profile.handle) || '';
  const name = useSelector((state) => state.UserAccountInfo.profile.name) || '';
  const shareTemplate = jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.share_handle)) || {};
  
  useEffect(() => {
    const { previousScreen = '', type = '' } = props;
    AnalyticsManager.logEvent(EventType.INFLUENCER.DERMAT_PROFILE, {
      [EventParameterKey.TYPE]: type,
      [EventParameterKey.SCREEN_NAME]: previousScreen,
    });
  }, []);

  const onTapEditProfile = () => {
    navigation.navigate('HandleSelection');
  };

  const onTapPreviewPage = () => {
    navigation.navigate('Influencer', { bioHandle: handle });
  };

  const copyReferralUrl = () => {
    const { messageTemplate: { greetingText = '', closingText = '' } = {} } = shareTemplate;
    AnalyticsManager.logEvent(EventType.INFLUENCER.DASHBOARD_INVITE, {
      handle,
    });
    const title = Config.APP_NAME;
    const url = `${isWeb() ? Config.WEB_URL : Config.URI_HOST}/@${handle}`;
    const message = `${greetingText} ${name}. ${closingText}`;
    if (isWeb()) {
      if (navigator.share) {
        navigator
          .share({
            title,
            text: message,
            url,
          })
          .then(() => toastRef.current.show('Shared Successfully! 🎉', 2000))
          .catch((err) => console.error(err));
      } else {
        Clipboard.setString(`${Config.URI_HOST}/@${handle}`);
      }
      return;
    }
    shareLink(title, message, url);
  };

  const shareLink = (title, message, url) => {
    Share.open({
      title,
      message: `${message} ${url}`,
    });
  };

  const buttonActions = {
    editProfile: onTapEditProfile,
    shareLink: copyReferralUrl,
  };


  const ButtonContainer = ({ buttonText, buttonIcon, type }) => {
    const { previousScreen = '' } = props;
    const onTapButton = () => {
      if (typeof buttonActions[type] == 'function') {
        AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_CLICK, {
          [EventParameterKey.TYPE]: EventType.INFLUENCER.DERMAT_PROFILE,
          [EventParameterKey.CTA]: buttonText,
          [EventParameterKey.SCREEN_NAME]: previousScreen,
        });
        buttonActions[type]();
      }
    };
    return (
      <TouchableOpacity onPress={onTapButton} style={styles.button}>
        <FastImageView
          source={getMinifiedImage(buttonIcon, styles.copyImage.width)}
          style={styles.buttonImage}
          tintColor={colors.white}
        />
        <Text style={styles.buttonText}>{buttonText}</Text>
      </TouchableOpacity>
    );
  };

  const trimmedHandle = `${handle}${handle.length > 20 ? '...' : ''}`;

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <Text style={styles.title}>Your FoxyRx Link</Text>
        <View style={styles.copyHandleContainer}>
          <Text
            style={styles.registrationCompletedSubheading}
            numberOfLines={1}
            ellipsizeMode='tail'
          >{`${Config.URI_HOST}/@${trimmedHandle}`}</Text>
          <TouchableOpacity onPress={copyReferralUrl} style={styles.copyTextContainer}>
            <FastImageView
              source={getMinifiedImage(images.copy_blue, styles.copyImage.width)}
              style={styles.copyImage}
            />
            <Text style={styles.copyText}>Copy</Text>
          </TouchableOpacity>
        </View>
        <TouchableOpacity onPress={onTapPreviewPage}>
          <Text style={styles.previewText}>Preview your page</Text>
        </TouchableOpacity>
        <View style={styles.buttonContainer}>
          <ButtonContainer buttonText={'Edit Page'} buttonIcon={images.edit_icon} type={'editProfile'} />
          <ButtonContainer buttonText={'Share Link'} buttonIcon={images.shareButton} type={'shareLink'} />
        </View>
      </View>
      <Toast style={styles.toast} ref={toastRef} />
    </View>
  );
};

export default DermatProfileEditCard;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    width: getScreenWidth() - 24,
    borderRadius: 12,
    alignContent: 'center',
    alignSelf: 'center',
    marginTop: 12,
  },
  subContainer: {
    padding: 12,
    alignItem: 'center',
  },
  title: {
    fontSize: 18,
    color: colors.black,
    fontFamily: 'Roboto-Bold',
  },
  dermatLinkText: {},
  previewText: {
    fontSize: 12,
    color: colors.foxyBlue,
    fontFamily: 'Roboto-Regular',
    textDecorationLine: 'underline',
  },
  bottomContainer: {},
  primaryContainer: {},
  secondaryContainer: {},
  copyHandleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
    width: getScreenWidth() - 100,
  },
  registrationCompletedSubheading: {
    fontSize: 16,
    fontFamily: 'Roboto-Regular',
    paddingRight: 8,
    color: colors.black,
    '@media (min-width: 768px)': {
      fontSize: 12,
      lineHeight: 23,
    },
  },
  copyTextContainer: {
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  copyImage: {
    height: 16,
    width: 16,
    resizeMode: 'contain',
  },
  buttonImage: {
    height: 16,
    width: 16,
    resizeMode: 'contain',
    tintColor: colors.white,
  },
  copyText: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlue,
  },
  previewTextContainer: {
    // alignItems: 'center',
  },
  buttonContainer: {
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    marginTop: 10,
  },
  button: {
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 20,
    width: getScreenWidth() / 2 - 50,
    backgroundColor: colors.black,
    height: 32,
    marginHorizontal: 10,
  },
  buttonText: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.white,
    paddingHorizontal: 8,
  },
  toast: { position: 'absolute', bottom: 20 },
});
