import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Linking,
} from 'react-native';
import React, { useEffect, useState, memo } from 'react';
import { useNavigation } from '@react-navigation/native';
import Contacts from 'react-native-contacts';
import colors from '../../theme/Colors';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import FastImageView from '../FastImageView';
import { AnalyticsManager } from '../../analytics';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import Utility from '../../utils/Utility';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { PERMISSION_STORE } from '../../config/Constants';
import images from '../../theme/Images';
import memoize from 'lodash/memoize';

const JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

const Alert = (props) => {
  const {
    item: { alert: alertItem = {} } = {},
    previousScreen,
    itemData = {},
    fullWidth,
  } = props;

  console.tron.log(props, 'alertItem');

  const navigation = useNavigation();
  const [alert, setAlert] = useState(itemData);

  useEffect(() => {
    try {
      new JSONAPIDeserializer({
        typeAsAttribute: false,
        pluralizeType: true,
        keyForAttribute: 'camelCase',
      })
        .deserialize(alertItem)
        .then((data = {}) => {
          setAlert(data);
          const { trackingData } = data;
          AnalyticsManager.logEvent('alert_view', {
            previousScreen,
            id: data.id,
            ...trackingData,
          });
        });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const {
    title = '',
    subtitle = '',
    imageUrl = '',
    ctaText = '',
    ctaUrl = '',
    ctaColor = colors.foxyBlack,
  } = alert || {};

  if (isBlank(title)) return null;

  const image = isBlank(imageUrl) ? images.alert_message : { uri: imageUrl };

  const openPhoneDialer = (phoneNumber) => {
    const url = `tel:${phoneNumber}`;
    Linking.canOpenURL(url)
      .then((supported) => {
        if (supported) {
          Linking.openURL(url);
        } else {
          console.error('Unable to open the phone dialer');
        }
      })
      .catch((err) => console.error('Error opening phone dialer:', err));
  };

  const addContact = async (params) => {
    let newPerson = {
      emailAddresses: [{ label: 'other', email: params.email }],
      phoneNumbers: [
        { label: 'mobile', number: params.mobile },
        { label: 'other', number: params.other },
      ],
    };

    newPerson = Utility.isIOS()
      ? { ...newPerson, givenName: params.name }
      : { ...newPerson, displayName: params.name };

    try {
      await Contacts.openContactForm(newPerson);
      console.log('Contact saved successfully');
    } catch (error) {
      console.error('Error saving contact:', error);
    }
  };

  const openEmail = (email, subject = '', body = '') => {
    const url = `mailto:${email}?subject=${encodeURIComponent(
      subject,
    )}&body=${encodeURIComponent(body)}`;
    Linking.canOpenURL(url)
      .then((supported) => {
        if (supported) {
          Linking.openURL(url);
        } else {
          console.error('Unable to open email client');
        }
      })
      .catch((err) => console.error('Error opening email client:', err));
  };

  const navigateToScreen = ({ route, slug, path, extra = {}, params, url }) => {
    AnalyticsManager.logEvent('item_action', {
      previousScreen,
      type: 'alert',
      route,
      ...params,
    });

    if (path === 'save_contact') {
      Utility.checkPermission(
        PERMISSION_STORE.read_contact,
        true,
        (isAllowed) => {
          if (isAllowed) addContact(params);
          else Utility.openSettings();
        },
      );
      return;
    }

    if (path === 'open_dialer') {
      openPhoneDialer(params.phone_number);
      return;
    }

    if (path === 'open_mailbox') {
      openEmail(params.email, params.subject, params.body);
      return;
    }

    navigation.navigate(route, { slug, extra, params, url });
  };

  const onButtonClick = () => {
    DynamicLinkManager.handleLinkWithInternalTrackingParams(
      ctaUrl,
      navigateToScreen,
    );
  };

  return (
    <DebouncedTouchableOpacity
      onPress={onButtonClick}
      style={fullWidth ? styles.fullWidthContainer : styles.container}
    >
      <View style={styles.innerContainer}>
        <FastImageView
          source={getMinifiedImage(image.uri, 40, 40)}
          style={styles.imageStyle}
        />
        <View style={styles.textContainer}>
          <Text style={styles.title} numberOfLines={1}>
            {title}
          </Text>
          <Text numberOfLines={2} style={styles.subtitle}>
            {subtitle}
          </Text>
        </View>
      </View>

      {isPresent(ctaText) && (
        <TouchableOpacity
          style={memoize(
            () => [
              styles.buttonStyle,
              { backgroundColor: ctaColor || colors.foxyBlack },
            ],
            () => [ctaColor],
          )()}
          onPress={onButtonClick}
        >
          <Text
            style={memoize(
              () => [
                styles.textStyle,
                { color: isPresent(ctaColor) ? '#000' : '#fff' },
              ],
              () => [ctaColor],
            )()}
            numberOfLines={1}
          >
            {ctaText}
          </Text>
        </TouchableOpacity>
      )}
    </DebouncedTouchableOpacity>
  );
};

export default memo(Alert);

const styles = StyleSheet.create({
  title: {
    fontSize: 10,
    color: '#060606',
    fontFamily: 'Roboto-Bold',
  },
  subtitle: {
    fontSize: 10,
    color: '#000000',
    fontFamily: 'Roboto-Regular',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: 12,
    backgroundColor: colors.white,
    paddingVertical: 12,
    paddingHorizontal: 12,
    margin: 1,
    borderRadius: 4,
  },
  fullWidthContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.white,
    paddingVertical: 12,
    paddingHorizontal: 12,
    margin: 1,
  },
  imageStyle: {
    height: 30,
    width: 30,
    marginRight: 8,
  },
  innerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  textContainer: {
    flex: 1,
    marginRight: 8,
  },
  buttonStyle: {
    width: 84,
    height: 22,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 18,
    paddingHorizontal: 4,
    paddingVertical: 4,
  },
  textStyle: { fontSize: 10, marginBottom: 1, fontFamily: 'Roboto-Regular' },
});
