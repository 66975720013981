import React, { PureComponent } from 'react';
import { ImageBackground, View, Image, Text } from 'react-native';
import PropTypes from 'prop-types';
import { styles as BannerStyles } from './styles';
import ScaleAnimate from '../shared/ScaleAnimate';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import CountDown from '../../helpers/Counter';
import FastImageView from '../FastImageView';
import NavigationService from '../../navigator/NavigationService';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';
import { isBlank, isPresent } from '../../utils/BooleanUtility';

class BannerCard extends PureComponent {
  constructor(props) {
    super(props);
    const { itemData: { image_url = '', image_webp_url = '' } = {} } =
      this.props;
    const image = Utility.isPresent(image_url) ? image_url : image_webp_url;
    this.newImageUrl = getMinifiedImage(image, Utility.getScreenWidth());

    this.imageUri = { uri: this.newImageUrl };
    this.navigationSource = getNavigationSource(this, this.props);
  }

  navigateToScreen = ({ route = '', slug = '', extra = {} }) => {
    const { authToken, navigation } = this.props;

    let newDestination = slug;
    if (route === 'ImageReview' && Utility.isBlank(authToken)) {
      NavigationService.renderOnboarding();
      Utility.invokeSelfieFlowAfterLoginForGuest();
      return;
    }

    if (route === 'RecycleWithFoxyWebView') {
      const { itemData: { destination = '' } = {} } = this.props;
      newDestination = destination;
    }

    navigateToScreen({
      navigation,
      type: 'push',
      screen: route,
      navigationSource: this.navigationSource,
      params: {
        newDestination, // webview reads data from destination
        slug: newDestination,
        extra,
      },
    });
  };

  onActionPressed = () => {
    const {
      navigation,
      itemData,
      listId,
      previousScreen,
      listIndex = 0,
      itemData: {
        destination,
        dynamic_destination,
        slug,
        id,
        external_link,
      } = {},
      allVideosId = '',
      navigateToOfferDetail,
      authToken,
      listData = {},
      listContent,
      listName,
      index,
      parentListsData = [],
      extraEventParameters = {},
    } = this.props;
    let url = isBlank(destination) ? '' : destination;
    url = isPresent(external_link) ? external_link : url;

    if (Utility.isBlank(url)) return;
    const listNames = url?.split('/')[url.split('/').length - 1];

    if (Utility.isPresent(itemData)) {
      const { id, type, listId } = itemData;
      const extraParams = {
        ...extraEventParameters,
        ...AnalyticsUtility.getParentsList(parentListsData),
      };
      AnalyticsUtility.fireItemClickEvent(
        previousScreen,
        id,
        type,
        listNames,
        index,
        listData?.id,
        'pager',
        listName,
        listIndex,
        '',
        '',
        listContent,
        id,
        slug,
        listData.slug,
        extraParams,
      );
    }

    if (navigateToOfferDetail) {
      let promptData = {
        type: 'list',
        id: id,
        offer: { slug },
        previousScreen,
        index: listIndex,
      };
      navigateToScreen({
        navigation,
        type: 'push',
        screen: 'OfferDetail',
        navigationSource: this.navigationSource,
        params: { promptData },
      });
      return;
    }
    if (Utility.isBlank(dynamic_destination)) {
      DynamicLinkManager.handleLinkWithInternalTrackingParams(
        url,
        this.navigateToScreen,
      );
    } else {
      navigation.navigate(this.navigationSource, 'PaginatedList', {
        id: allVideosId,
        dynamicDestinationSlug: dynamic_destination,
      });
    }
  };

  timerOfferText = () => {
    const { endsAt, onTimerFinish } = this.props;
    return (
      <View style={BannerStyles.bannerWithTimer}>
        <Text style={BannerStyles.bannerWithTimerText}>Offer ends in</Text>

        <CountDown
          size={10}
          until={Utility.calculateTimeDifferenceForOffer(endsAt)}
          endsAt={endsAt}
          onFinish={onTimerFinish}
          digitStyle={BannerStyles.bannerTimerDigitStyle}
          digitTxtStyle={{ color: '#fff', fontSize: 16 }}
          timeLabelStyle={BannerStyles.bannerTimerLabelStyle}
          separatorStyle={BannerStyles.bannerTimerSeparatorStyle}
          timeToShow={['H', 'M', 'S']}
          timeLabels={{ h: 'hrs', m: 'mins', s: 'sec' }}
          showSeparator
        />
      </View>
    );
  };

  timerSection = () => {
    const { itemData, endsAt } = this.props;
    if (Utility.isBlank(itemData)) {
      return null;
    }

    return (
      <View style={BannerStyles.bannerContainer}>
        <Image
          resizeMode='stretch'
          source={this.imageUri}
          style={BannerStyles.bannerImageStyle}
          blurRadius={80}
        />

        <View style={BannerStyles.bannerTimerContainer}>
          <this.timerOfferText />
        </View>
      </View>
    );
  };

  render() {
    const {
      itemData,
      endsAt,
      offerBannerWithTimer = false,
      onTimerFinish,
    } = this.props;
    if (itemData === undefined) {
      return null;
    }
    if (
      offerBannerWithTimer &&
      Utility.calculateTimeDifferenceForOffer(endsAt) <= 0
    ) {
      onTimerFinish();
    }

    return (
      <ScaleAnimate {...this.props} onPress={this.onActionPressed}>
        <FastImageView
          resizeMode='stretch'
          source={this.newImageUrl}
          style={BannerStyles.bannerImage}
        />

        {offerBannerWithTimer &&
          Utility.calculateTimeDifferenceForOffer(endsAt) <= 86400 && (
            <this.timerSection />
          )}
      </ScaleAnimate>
    );
  }
}

BannerCard.propTypes = {
  itemData: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    image_url: PropTypes.string,
  }),
};

export default withProfiledNavigation(BannerCard);
