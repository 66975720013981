import React, { PureComponent } from 'react';
import { View, Text, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FastImageView from '../FastImageView';
import {
  updateUserAttributeRemote,
  setGenderUserAttribute,
} from '../../actions/LoginActions';
import { uacGenderEventTriggered } from '../../actions/ActionTypes';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';

class SelectGenderCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedGender: null, // To track selected gender
    };
  }

  textView = (props) => {
    const { text, header } = props;
    if (Utility.isBlank(text)) return null;
    return (
      <Text style={header ? styles.headerText : styles.descText}>{text}</Text>
    );
  };

  genderSelection = (props) => {
    const { text, imgSrc, onPress, selected } = props;
    const selectionStyle = selected
      ? styles.selectedGender
      : styles.genderSelection;
    return (
      <TouchableWithoutFeedback onPress={onPress}>
        <View style={selectionStyle}>
          {Utility.isPresent(imgSrc) && (
            <FastImageView style={styles.genderIcons} source={imgSrc} />
          )}
          <this.textView header={true} text={text} />
        </View>
      </TouchableWithoutFeedback>
    );
  };

  onPressGenderButton = (gender) => {
    this.setState({ selectedGender: gender }, this.handlePersonalizeMyFeed);
  };

  onPressMaleGender = () => {
    this.onPressGenderButton('male');
  };

  onPressFemaleGender = () => {
    this.onPressGenderButton('female');
  };

  handlePersonalizeMyFeed = () => {
    const {
      updateUserAttributeRemote,
      setGenderUserAttribute,
      onRefresh = () => {},
    } = this.props;
    const { selectedGender } = this.state;

    const genderId = selectedGender === 'male' ? 62 : 63;
    const genderObject = { id: 28, new_values: [genderId] };
    const genderObjectLocal = { user_attribute_id: 28, new_values: [genderId] };

    // updateUserAttributeRemote(genderObject);
    setTimeout(() => {
      setGenderUserAttribute(genderObjectLocal);
      setTimeout(() => {
        onRefresh();
      }, 200);
    }, 200);

    // Trigger the refresh after updating gender
  };

  render() {
    const {
      headerText,
      descriptionText,
      showFooterRetakeButton,
      onPressRetakeButton,
      showGenderCard,
      facialAnalysis: { my_attributes_values },
    } = this.props;
    const { selectedGender } = this.state;

    const genderExists = Utility.isGenderAttributeExists(my_attributes_values);

    if (genderExists) return null;

    return (
      <View style={styles.wrapper}>
        <View style={styles.container}>
          {/* Added Heading and Subheading */}
          <Text style={styles.mainHeading}>Personalize Your Feed</Text>
          <Text style={styles.subHeading}>
            Select your gender to get personalized content
          </Text>

          <this.textView header={true} text={headerText} />
          <this.textView text={descriptionText} />

          {!showFooterRetakeButton && (
            <View style={styles.genderRow}>
              <View style={{ flex: 1 }} testID='women-selection'>
                <this.genderSelection
                  text='Women'
                  imgSrc={images.gender.female.true}
                  onPress={this.onPressFemaleGender}
                  selected={selectedGender === 'female'}
                />
              </View>
              <View style={styles.spacer} />
              <View style={{ flex: 1 }} testID='men-selection'>
                <this.genderSelection
                  text='Men'
                  imgSrc={images.gender.male.true}
                  onPress={this.onPressMaleGender}
                  selected={selectedGender === 'male'}
                />
              </View>
            </View>
          )}
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  facialAnalysis: state.UserAccountInfo.facialAnalysis,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserAttributeRemote,
      setGenderUserAttribute,
      uacGenderEventTriggered,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SelectGenderCard);

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    paddingHorizontal: 16,
    marginTop: 16,
  },
  container: {
    padding: 16,
    backgroundColor: colors.lightGreyBackground,
    borderRadius: 8,
  },
  mainHeading: {
    fontFamily: 'Roboto-Bold',
    fontSize: size.h2,
    color: colors.black,
    marginBottom: 8,
    textAlign: 'center',
  },
  subHeading: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    color: colors.darkGrey,
    marginBottom: 24,
    textAlign: 'center',
  },
  headerText: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
    color: colors.black,
    // marginBottom: 8,
    textAlign: 'center',
  },
  descText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    color: colors.darkGrey,
    // marginBottom: 16,
    textAlign: 'center',
  },
  genderSelection: {
    height: 40,
    borderRadius: 8,
    backgroundColor: colors.lightGrey,
    borderColor: colors.grey,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    flexDirection: 'row',
    paddingHorizontal: 16,
  },
  selectedGender: {
    height: 40,
    borderRadius: 8,
    backgroundColor: colors.primary,
    borderColor: colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    flexDirection: 'column',
    paddingHorizontal: 16,
  },
  genderRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  spacer: {
    width: 16,
  },
  genderIcons: {
    marginRight: 8,
    height: 20,
    width: 20,
  },
});
