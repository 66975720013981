import { StyleSheet, Platform } from 'react-native';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';

const CollectionCardStyle = StyleSheet.create({
  cardContainer: {
    margin: 4,
    borderRadius: 4,
    overflow: 'hidden',
  },

  personalisedCollectionCartText: {
    position: 'absolute',
    fontSize: 27,
    color: colors.white,
    alignSelf: 'center',
    textAlign: 'center',
    bottom: 10,
    fontFamily: 'Roboto-Bold',
  },
  cardContainerList: {
    width: Utility.getScreenWidth(),
    flexDirection: 'row',
    backgroundColor: colors.white,
    alignItems: 'center',
  },
  ListHeadingText: {
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
    fontSize: size.h4,
    fontStyle: 'normal',
    lineHeight: 14,
    width: Utility.getScreenWidth() - 120,
  },
  ListSubHeadingText: {
    fontFamily: 'Roboto-Regular',
    color: colors.silver,
    fontSize: size.h4,

    fontStyle: 'normal',
    lineHeight: 14,
    marginTop: 5,
  },
  listSeparatorStyle: {
    height: 1,
    backgroundColor: '#eaeaea',
  },
  descriptionContainer: {
    width: Utility.getScreenWidth() - 48,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
  },
  playMore: {
    marginRight: 22,
  },
  collectionCardPlaceholderText: {
    position: 'absolute',
    bottom: 12,
    fontSize: size.h3,
    color: colors.white,

    alignSelf: 'center',
    textAlign: 'center',
    marginLeft: 4,
    marginRight: 4,
  },
  collectionCardEmptyImageContainer: {
    position: 'absolute',
    bottom: 0,
    height: '100%',
    width: '100%',
  },
  collectionCardEmptyImageGradient: {
    flex: 1,
    opacity: 1,
    // height: '10%',
    bottom: 0,
  },
  collectionCardEmptyImageCardText: {
    paddingHorizontal: 4,
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    zIndex: 100,
    position: 'absolute',
    bottom: 8,
    alignSelf: 'center',
    textAlign: 'center',
  },
  colouredCardContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    margin: 4,
    borderRadius: 4,
  },
  colouredCardContainerHorizontal: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 4,
    borderRadius: 4,
  },
  colouredCardImageStyle: {
    position: 'absolute',
    top: 8,
    bottom: 48,
    aspectRatio: 1,
    borderRadius: 4,
  },
  colouredCardRectangleDefaultSizeImageStyle: {
    position: 'absolute',
    right: -12,
    bottom: -12,
    aspectRatio: 1,
    borderRadius: 4,
  },
  colouredCardRectangleSmallImageStyle: {
    position: 'absolute',
    right: -10,
    bottom: -10,
    aspectRatio: 1,
    borderRadius: 4,
  },
  colouredCardTextStyle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    alignSelf: 'center',
    textAlign: 'center',
  },
  colouredMediumCardTextStyle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    alignSelf: 'center',
    textAlign: 'center',
  },
  colouredSmallCardTextStyle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 12,
    alignSelf: 'center',
  },
  colouredMediumRectangleCardTextStyle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    position: 'absolute',
    top: 0,
  },
  colouredSmallRectangleCardTextStyle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    position: 'absolute',
    top: 0,
    textAlign: 'left',
  },
  colouredCardTextContainer: {
    position: 'absolute',
    marginLeft: 8,
    marginRight: 8,
    bottom: 8,
    height: 38,
    justifyContent: 'center',
  },
  colouredMediumCardTextContainer: {
    position: 'absolute',
    marginLeft: 8,
    marginRight: 8,
    bottom: 8,
    height: 34,
    justifyContent: 'center',
  },
  colouredCardRectangleDefaultSizeTextContainer: {
    position: 'absolute',
    marginLeft: 12,
    top: 12,
    bottom: 12,
    width: 86,
  },
  colouredCardRectangleSmallTextContainer: {
    position: 'absolute',
    marginLeft: 8,
    top: 8,
    bottom: 4,
    width: 86,
  },
  colouredCardCircularDefaultSizeTextContainer: {
    position: 'absolute',
    bottom: 30,
    width: 140,
    alignSelf: 'center',
  },
  colouredCardCircularDefaultSizeTextStyle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    position: 'absolute',
    top: 0,
    textAlign: 'center',
    alignSelf: 'center',
  },
  railColouredCardTextContainer: {
    position: 'absolute',
    marginLeft: 8,
    marginRight: 8,
    bottom: 8,
    height: 34,
    justifyContent: 'center',
  },
  gridRailColouredCardTextContainer: {
    position: 'absolute',
    marginLeft: 8,
    top: 8,
    bottom: 4,
    width: 86,
  },
  raildefaultSizeColouredCardTextStyle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    position: 'absolute',
    top: 0,
    textAlign: 'left',
  },
  rectangularGridColouredCardTextContainer: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    position: 'absolute',
    top: 0,
  },
  rectangularGridColouredCardTextStyle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    position: 'absolute',
    top: 0,
  },
  smallGridColouredCardTextContainer: {
    position: 'absolute',
    bottom: 30,
    width: 140,
    alignSelf: 'center',
  },
  smallGridColouredCardTextStyle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    position: 'absolute',
    top: 0,
    textAlign: 'center',
    alignSelf: 'center',
  },
});

export default CollectionCardStyle;
