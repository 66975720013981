
// Dependencies
import React, { PureComponent } from 'react';
import { Text, View, TouchableOpacity, Image } from 'react-native';
import PropTypes from 'prop-types';
// Components
import { compose } from 'recompose';
import styles from './styles';
import {
  COUNTDOWN,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  LAYOUT,
  MORE_ITEMS,
  CURRENCY_SYMBOL,
  AddressBeforeLoginAppConfig,
  EnableShopifyDirect,
} from '../../config/Constants';
import LAYOUT_CONFIG from '../../config/LayoutConstants/LayoutConfig';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { withMaybe, withEither } from '../../lib/Monads';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import _ from 'lodash';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import EmptyState from '../layout/emptyState/Emptystate';
import images from '../../theme/Images';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { style } from '../StoryDetails/RecommendationStyle';
import FastImageView from '../FastImageView';
import CountDown from '../../helpers/Counter';
import size from '../../theme/Fonts';
import colors from '../../theme/Colors';
import TimeUtility from '../../utils/TimeUtility';
import CountDownWithoutText from '../../helpers/CounterWithoutText';
import Timer from '../../helpers/Timer';
import NumberCounter from '../../helpers/NumberCounter';
import { connect } from 'react-redux';
import StringUtils from '../../utils/StringUtils';
import { isPresent, isWeb } from '../../utils/BooleanUtility';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import { navigateToScreen } from '../../utils/NavigationUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';
import AppConfig from '../../config/AppConfig';

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.item = this.props.item || {};
    this.state = {
      isCartVisible: false,
    };
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    this.memoizedSubtitleStyles = {};
    this.memoizedHeaderTitleColor = {};
    this.memoizedHeaderTitleLineHeight = {};
    this.memoizedDpContainerStyle = {};
    this.memoizedActionButtonColor = {};
    const { hideTopMargin } = this.props;
    this.containerStyle = hideTopMargin
      ? styles.headerHeadingContainerWithoutMargin
      : styles.headerHeadingContainer;
    if (this.item.display === 'multi-level-tabs') {
      this.containerStyle = [this.containerStyle, { marginBottom: 12 }];
    }

    this.navigationSource = getNavigationSource(this, this.props);
  }

  handleRouteFromLink = ({ route = '', slug = '', extra = {} }) => {
    const { navigation } = this.props;
    navigateToScreen({
      navigation,
      type: 'push',
      screen: route,
      navigationSource: this.navigationSource,
      params: { slug, extra },
    });
  };

  navigate = () => {
    const {
      navigation,
      previousScreen = '',
      navigateToOfferDetail,
      item,
      moreClick = () => {},
      searchQuery,
      index,
      slug,
      id,
      extraEventParameters = {},
      is_dynamic = false,
    } = this.props;
    moreClick();
    if (Utility.isPresent(item)) {
      const moreText =
        item.id === 'cart-items'
          ? 'Checkout'
          : item?.additional_data?.action?.text ?? '';
      AnalyticsUtility.fireItemClickEvent(
        previousScreen,
        '',
        moreText,
        item?.name,
        '',
        item?.id || id,
        item?.display,
        item?.name,
        index,
        '',
        '',
        item?.content,
        '',
        '',
        item?.slug,
        extraEventParameters,
      );
    }
    if (Utility.isPresent(item?.additional_data?.action?.destination)) {
      DynamicLinkManager.handleLinkWithInternalTrackingParams(
        item?.additional_data?.action?.destination,
        this.handleRouteFromLink,
      );
      return;
    }

    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      navigateToScreen({
        navigation,
        type: 'push',
        screen: 'MoreItems',
        navigationSource: this.navigationSource,
        params: {
          slug: this.item.slug,
          previousScreen: SCREEN_CONSTANTS.SEARCH,
          searchQuery,
          search: true,
          content: this.item.content,
        },
      });
      return;
    }
    if (navigateToOfferDetail) {
      navigateToScreen({
        navigation,
        type: 'push',
        screen: 'OfferDetail',
        navigationSource: this.navigationSource,
        params: {
          promptData: {
            type: 'list',
            id,
            offer: { slug },
            index,
            previousScreen,
          },
        },
      });
      return;
    }
    if (this.item.id === 'cart-items') {
      this.props.navigation.push(this.navigationSource, 'Cart');
      return;
    }

    let screenName = MORE_ITEMS;
    if (EnableShopifyDirect && item.slug.includes('shopify_collections')) {
      screenName = SCREEN_CONSTANTS.SHOPIFY_COLLECTIONS;
    }

    navigateToScreen({
      navigation,
      type: 'push',
      screen: screenName,
      navigationSource: this.navigationSource,
      params: {
        id: this.item.id,
        slug: this.item.slug,
        displayCount: this.item?.objects?.length,
        heading: this.item.name,
        item: this.item,
        navigation,
        title: this.item.name,
        listData: this.item,
        previousScreen,
        index,
        args: this.item?.arguments,
      },
    });
  };

  showMoreButtonForDynamicList = (params) => {
    const { recentlyViewedProductIds = EMPTY_ARRAY } = this.props;
    const {
      args = EMPTY_ARRAY,
      displayCount,
      is_dynamic,
      previousScreen,
    } = params;
    if (params.itemLength >= displayCount && is_dynamic) {
      return true;
    }

    if (!args.length) return false;
    const { value } =
      args.find(({ param }) => param === 'product_ids') || EMPTY_OBJECT;
    if (value === 'recentlyViewedProductIds') {
      return recentlyViewedProductIds.length > displayCount;
    }
    return false;
  };

  showMoreCondition = (props) => {
    if (props?.showMoreButton) {
      return false;
    }
    if (this.showMoreButtonForDynamicList(props)) {
      return false;
    }

    return (
      props.itemLength <= props.displayCount ||
      props.layout === 'story-rail' ||
      props.content === 'media_category' ||
      props.layout === 'horizontal-playlist' ||
      props.layout === 'timerBannerWithRail' ||
      (props.productAndBrandsOnlySwitchValue && props.content === 'product') ||
      (props.productAndBrandsOnlySwitchValue &&
        props.content === 'quick_filters')
    );
  };

  moreButton = withMaybe(this.showMoreCondition)(TouchableOpacity);

  cartButtonCondition = (props) => props.id === 'cart-items';

  actionButton = compose(
    withEither(this.cartButtonCondition, TouchableOpacity),
    withMaybe(this.showMoreCondition),
  )(TouchableOpacity);

  onCartDismiss = () => {
    this.setState({ isCartVisible: false });
  };

  getMemoizedHeaderTitleLineHeight = (headerStyle, containEmoji) => {
    const lineHeight = containEmoji ? 25 : 21;
    if (!this.memoizedHeaderTitleLineHeight[containEmoji]) {
      this.memoizedHeaderTitleLineHeight[containEmoji] = [
        headerStyle,
        { lineHeight },
      ];
    }
    return this.memoizedHeaderTitleLineHeight[containEmoji];
  };

  getMemoizedDpIconContainerStyle = (dpIconContainerStyle, width) => {
    const containerWidth = isPresent(width)
      ? width
      : Utility.getScreenWidth() - 32;
    if (!this.memoizedDpContainerStyle[containerWidth]) {
      this.memoizedDpContainerStyle[containerWidth] = [
        dpIconContainerStyle,
        { width: containerWidth },
      ];
    }
    return this.memoizedDpContainerStyle[containerWidth];
  };

  getMemoizedHeaderTitleColor = (headerStyle, titleColor) => {
    if (!this.memoizedHeaderTitleColor[titleColor]) {
      this.memoizedHeaderTitleColor[titleColor] = [
        headerStyle,
        { color: titleColor, flex: 1 },
      ];
    }
    return this.memoizedHeaderTitleColor[titleColor];
  };

  getMemoizedActionButtonColor = (headerStyle, titleColor) => {
    if (!this.memoizedActionButtonColor[titleColor]) {
      this.memoizedActionButtonColor[titleColor] = [
        headerStyle,
        { color: titleColor },
      ];
    }
    return this.memoizedActionButtonColor[titleColor];
  };

  getMemoizedSubtitleStyle = (subtitleStyles, color) => {
    if (!this.memoizedSubtitleStyles[color]) {
      this.memoizedSubtitleStyles[color] = [
        subtitleStyles,
        { color },
      ];
    }
    return this.memoizedSubtitleStyles[color];
  };

  Subtitle = () => {
    const { item: { subtitle = '', subtitle_color = '' } = {} } = this.props;
    if (Utility.isBlank(subtitle)) {
      return null;
    }

    if (Utility.isPresent(subtitle)) {
      const subtitleColor = Utility.isPresent(subtitle_color)
        ? subtitle_color
        : colors.grey;

      const subtitleStyles = StringUtils.isEmojiPresent(subtitle)
        ? styles.headingSubtitleWithEmoji
        : styles.headingSubtitle;
      return (
        <Text
          style={this.getMemoizedSubtitleStyle(subtitleStyles, subtitleColor)}
          ellipsizeMode='tail'
          numberOfLines={2}
        >
          {subtitle}
        </Text>
      );
    }
  };

  onTimerFinish = () => {
    const { item, onTimerFinish = () => {} } = this.props;
    onTimerFinish(item);
  };

  renderCounter = () => {
    const {
      item: {
        subtitle,
        options: {
          counter_max_value,
          counter_current_value = 0,
          counter_suffix = '',
        } = {},
      },
    } = this.props;
    // Counter should be visible only when title and subtitle are present. Keeping check
    // of only subtitle here, as subtitle will come only when title comes.
    if (Utility.isBlank(subtitle) || Utility.isBlank(counter_max_value)) {
      return null;
    }

    return (
      <NumberCounter
        counter_max_value={counter_max_value}
        counter_current_value={counter_current_value}
        counter_suffix={counter_suffix}
      />
    );
  };

  showHeaderImage = ({ selfieImageUrl, headerImageUrl, headerStyle }) => {
    let imageSource = '';
    const imageSources = {
      selfie_only: selfieImageUrl,
      image: headerImageUrl,
    };

    if (headerStyle in imageSources) {
      imageSource = imageSources[headerStyle];
    } else {
      return null;
    }
    return (
      (!!Utility.isPresent(selfieImageUrl) ||
        !!Utility.isPresent(headerImageUrl)) && (
        <FastImageView source={imageSource} style={styles.headerImageStyle} />
      )
    );
  };

  navigateToWallet = () => {
    const { navigation } = this.props;
    if (!AddressBeforeLoginAppConfig) {
      navigation.push('MyWallet');
    }
  };

  renderHeaderRightComponent = () => {
    const { item } = this.props;
    if (
      Utility.isBlank(item?.additional_data?.total_balance) ||
      parseInt(item?.additional_data?.total_balance) <= 0
    ) {
      return null;
    }
    return (
      <DebouncedTouchableOpacity
        {...this.props}
        onPress={this.navigateToWallet}
      >
        <Text
          style={styles.headerRightElementText}
        >{`${CURRENCY_SYMBOL}${item?.additional_data?.total_balance}`}</Text>
        {!isWeb() && (
          <FastImageView
            source={images.chevron_green}
            style={styles.chevron}
            resizeMode='contain'
          />
        )}
      </DebouncedTouchableOpacity>
    );
  };

  render() {
    const {
      layout,
      feed,
      previousScreen,
      title,
      showMoreButton,
      productAndBrandsOnlySwitchValue,
      hideHeader,
      item,
      showFooter = false,
      item: {
        options: { ends_at = '', show_timer_in_subtitle = false } = {},
        header_style: headerStyle = '',
        header_image_url: headerImageUrl = '',
      } = {},
      selfieImageUrl,
      hideTopMargin = false,
      extraStyleParameter = {},
      extraStyleParameter: {
        width: headerContainerWidth = '',
        color: actionButtonColor = colors.cta.lightBlue,
      } = {},
      is_dynamic,
    } = this.props;
    if (
      previousScreen === SCREEN_CONSTANTS.MEMBER_LANDING_PAGE &&
      item?.objects?.length === 0
    ) {
      return null;
    }

    if (hideHeader) return null;
    this.item = this.props.item;
    if (Utility.isBlank(this.item) || Utility.isBlank(this.item.name)) {
      return null;
    }

    if (this.item.content === 'product' && this.item.display === 'card') {
      // dont show header in these displays
      return null;
    }

    if (
      this.item?.objects?.length === 0 &&
      !item?.additional_data?.action?.destination?.includes('wishlist') &&
      !this.item?.is_dynamic
    ) {
      return null;
    }

    if (
      layout === LAYOUT.RAIL &&
      this.item?.objects?.length < LAYOUT_CONFIG.minRailCount
    ) {
      if (
        (previousScreen === SCREEN_CONSTANTS.FEED ||
          previousScreen === SCREEN_CONSTANTS.STORE) &&
        !item?.is_dynamic
      )
        return null;
    }
    const headerTitle = title ? title : this.item.name;
    const subtitle = this.item.subtitle;
    const titleColor = this.item.name_color ? this.item.name_color : '#173143';
    let moretext = this.item.id === 'cart-items' ? 'Checkout' : 'More';
    if (
      this.item.display === 'personalized-grid' ||
      previousScreen === 'recommendation' ||
      this.item.display === 'tabbed' ||
      this.item.display === 'section' ||
      this.item.display === 'vertical-playlist' ||
      this.item.display === 'offer-timer-banner-with-rail' ||
      this.item.display === 'pager' ||
      this.item.display === 'hero_rail' ||
      this.item.display === 'staggered_grid' ||
      this.item.display === 'listWithDesc' ||
      (previousScreen === SCREEN_CONSTANTS.SEARCH &&
        this.item.name === 'Top results')
    ) {
      moretext = '';
    }
    if (
      previousScreen !== SCREEN_CONSTANTS.MORE_PAGE &&
      Utility.isPresent(this.item.objects) &&
      this.item.display === LAYOUT.GRID &&
      !item?.additional_data?.action?.destination?.includes('wishlist')
    ) {
      const itemCount = this.item?.objects?.length;
      if (itemCount === 0) {
        return null;
      }
    }

    if (Utility.isPresent(item?.additional_data?.action)) {
      moretext = item?.additional_data?.action?.text;
    }

    const titleImage = item?.additional_data?.title_image;

    const iconStyle = Utility.isPresent(titleImage)
      ? styles.headingTitleContainerIcon
      : styles.headingTitleContainer;

    const headingTitleStyle = hideTopMargin
      ? styles.headingTitleWithWidth
      : styles.headingTitle;
    const isSelfieOrImage =
      (headerStyle === 'selfie_only' && Utility.isPresent(selfieImageUrl)) ||
      (headerStyle === 'image' && Utility.isPresent(headerImageUrl));
    const dpIconContainerStyle =
      isSelfieOrImage && Utility.isBlank(subtitle)
        ? styles.headingTitleContainerDpIcon
        : iconStyle;
    const iconContainerStyle = this.getMemoizedDpIconContainerStyle(
      dpIconContainerStyle,
      headerContainerWidth,
    );
    const headingTitleContainerStyle = isSelfieOrImage
      ? styles.headingTitleDpIcon
      : headingTitleStyle;

    const headerTitleBaseStyle = this.getMemoizedHeaderTitleLineHeight(
      headingTitleContainerStyle,
      StringUtils.isEmojiPresent(headerTitle),
    );
    const moreButtonStyle = isSelfieOrImage
      ? styles.moreButtonWithImageStyle
      : styles.morebuttonstyle;
    const actionButtonStyle = this.getMemoizedActionButtonColor(
      moreButtonStyle,
      actionButtonColor,
    );
    /**
     * Header title component needs textColor also, previously it was inline.
     * Create two different function  *getMemoizedHeaderTitleLineHeight* so that
     * first it decide what is base style (with/without lineHeight), after that add text color to that style.
     *
     */
    const headerTitleStyleWithTextColor = this.getMemoizedHeaderTitleColor(
      headerTitleBaseStyle,
      titleColor,
    );

    return (
      <>
        <View style={this.containerStyle}>
          <View style={styles.headerSubContainer}>
            <this.showHeaderImage
              selfieImageUrl={selfieImageUrl}
              headerStyle={headerStyle}
              headerImageUrl={headerImageUrl}
            />
            <View>
              <View style={iconContainerStyle}>
                {Utility.isPresent(titleImage) && (
                  <FastImageView
                    resizeMode='contain'
                    source={titleImage}
                    height={28}
                    width={28}
                    style={styles.headerImage}
                  />
                )}
                <Text
                  style={headerTitleStyleWithTextColor}
                  ellipsizeMode='tail'
                  numberOfLines={1}
                >
                  {headerTitle}
                </Text>
                <this.renderHeaderRightComponent />
                <this.renderCounter />
                {/* Timer should be visible only when title and subtitle are present. Keeping check
            of only subtitle here, as subtitle will come only when title comes. */}
                {show_timer_in_subtitle == 1 && Utility.isPresent(subtitle) ? (
                  <Timer ends_at={ends_at} onTimerFinish={this.onTimerFinish} />
                ) : (
                  <this.actionButton
                    onPress={this.debouncedNavigate}
                    itemLength={this.item?.objects?.length || 0}
                    displayCount={this.item?.display_count || 0}
                    id={this.item.id}
                    layout={layout}
                    content={this.item.content}
                    hitSlop={Utility.getHitSlop()}
                    productAndBrandsOnlySwitchValue={
                      productAndBrandsOnlySwitchValue
                    }
                    showMoreButton={item?.additional_data?.show_more}
                    args={item?.arguments}
                    headerTitle={headerTitle}
                    is_dynamic={is_dynamic}
                    previousScreen={previousScreen}
                  >
                    <View style={styles.actionButtonContainer}>
                      <Text style={actionButtonStyle}>{moretext}</Text>
                    </View>
                  </this.actionButton>
                )}
              </View>
              <this.Subtitle />
              {item?.additional_data?.action?.destination?.includes(
                'wishlist-builder',
              ) &&
                item?.objects?.length === 0 && (
                  <EmptyState
                    title='Tell us what you want'
                    subtitle='Add product to your wishlist to see them in your flash deals'
                    buttonText='Let’s do it'
                    image={images.empty_wishlist}
                    onPress={this.debouncedNavigate}
                  />
                )}
            </View>
          </View>
        </View>
      </>
    );
  }
}

// PropTypes
Header.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    objects: PropTypes.objects,
  }),
  layout: PropTypes.string,
};

const mapStateToProps = (state) => ({
  selfieImageUrl: state.UserAccountInfo.profile.selfie_image_url,
  recentlyViewedProductIds: state.UserAccountInfo.recentlyViewedProductIds,
});

export default withProfiledNavigation(connect(mapStateToProps, null)(Header));
