import React, { PureComponent } from 'react';
import { View } from 'react-native';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ScaleAnimate from '../shared/ScaleAnimate';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import BannerDescription from './BannerDescription';
import { ids, styles } from './styles';
import FastImageView from '../FastImageView';
import colors from '../../theme/Colors';
import { isDesktop } from '../../utils/BooleanUtility';
import { getTabsRoutes, navigateToScreen } from '../../utils/NavigationUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';
import DebouncedTouchableWithoutFeedback from '../shared/DebouncedTouchableWithoutFeedback';


class LargeBanner extends PureComponent {
  constructor(props) {
    super(props);
    const { itemData: { image_url = '' } = {} } = this.props;
    this.debouncedNavigate = _.debounce(this.onActionPressed, 200, {
      leading: true,
      trailing: false,
    });
    const imageWidth = styles.largeBannerImage.width;
    const imageHeight = styles.largeBannerImage.height;
    this.newImageUrl = getMinifiedImage(image_url, imageWidth, imageHeight);
    this.navigationSource = getNavigationSource(this, this.props);
  }

  navigateToScreen = ({ route = '', slug = '', extra = {} }) => {
    const { navigation } = this.props;
    if (getTabsRoutes().includes(route)) {
      navigation.navigate(this.navigationSource, route, { slug, destination: slug });
      return;
    }

    navigateToScreen({
      navigation,
      type: 'push',
      screen: route,
      navigationSource: this.navigationSource,
      params: {
        destination: slug, // webview reads data from destination
        slug,
        extra,
      },
    });
  };

  onActionPressed = () => {
    const {
      navigation,
      itemData,
      previousScreen,
      listIndex = 0,
      listId = '',
      listSlug = '',
      itemData: {
        id = '',
        type = '',
        destination,
        dynamic_destination,
        slug,
        external_link,
        display = '',
        title = '',
      } = {},
      allVideosId = '',
      listContent,
      listName,
      parentListsData,
      index,
    } = this.props;

    const url = Utility.isPresent(external_link) ? external_link : destination;

    const listNames = url.split('/')[url.split('/').length - 1];

    if (Utility.isPresent(itemData)) {
      AnalyticsUtility.fireItemClickEvent(
        previousScreen,
        id,
        type,
        listNames,
        index,
        listId,
        display,
        Utility.isPresent(listName) ? listName : title,
        listIndex,
        '',
        '',
        listContent,
        '',
        slug,
        listSlug,
        AnalyticsUtility.getParentsList(parentListsData),
      );
    }

    if (Utility.isBlank(dynamic_destination)) {
      DynamicLinkManager.handleLinkWithInternalTrackingParams(
        url,
        this.navigateToScreen,
      );
    } else {
      navigation.push('PaginatedList', {
        id: allVideosId,
        dynamicDestinationSlug: dynamic_destination,
      });
    }
  };

  render() {
    const {
      itemData = {},
      itemData: { image_url = '' } = {},
      listData: { options: listOptions = {} } = {},
    } = this.props;
    const { title, body, cta_text, cta_color } = itemData;

    if (Utility.isBlank(itemData) || Utility.isBlank(image_url)) {
      return null;
    }

    const options = Utility.isPresent(listOptions) ? listOptions : {};

    const { show_borders_for_list_items = 0 } = options;

    const largeBannerStyle =
      show_borders_for_list_items == 1
        ? [
            styles.largeBanner,
            {
              borderWidth: 1,
              borderColor: colors.border,
            },
          ]
        : styles.largeBanner;

    return (
      <ScaleAnimate {...this.props}>
        <View
          style={[styles.flexContainer, isDesktop() && { backgroundColor: cta_color }]}
          dataSet={{ media: ids.flexContainer }}
        >
          <View style={largeBannerStyle} dataSet={{ media: ids.largeBanner }}>
            <DebouncedTouchableWithoutFeedback onPress={this.debouncedNavigate}>
              <FastImageView
                resizeMode='stretch'
                source={this.newImageUrl}
                style={styles.largeBannerImage}
                height={isDesktop() ? (Utility.getScreenWidth() - 24) / 1.7 / 2 : undefined}
              />
            </DebouncedTouchableWithoutFeedback>

            <BannerDescription
              heading={title}
              subHeading={body}
              buttonText={cta_text}
              ctaColor={cta_color}
              onPress={this.debouncedNavigate}
              style={styles.bannerDescriptionStyle}
            />
          </View>
        </View>
      </ScaleAnimate>
    );
  }
}

export default withProfiledNavigation(LargeBanner);
