import React from 'react';
import { View } from 'react-native';
import Card from '../../lib/Card';
import { ScaleAnimate } from '../shared';
import { StyleSheet } from 'react-native';
import colors from '../../theme/Colors';
import {
  getDynamicWidthForGrid,
  getScreenWidth,
} from '../../utils/LayoutUtility';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import Config from 'react-native-config';
import ShopifyProductDescription from './ShopifyProductDescription';
import { LAYOUT } from '../../config/Constants';
import ShopifyProductImageAndRating from './ShopifyProductImageAndRating';
import ProductOtherVariants from '../variants/ProductOtherVariants';
import AddToCart from '../Product/AddToCart';
import PRODUCT from '../../config/LayoutConstants/ProductConfig';
import { useNavigation } from '@react-navigation/native';
import LinearGradient from 'react-native-linear-gradient';
import Utility from '../../utils/Utility';
import { memoize } from 'lodash';
import { isVariantInStockShopifyDirect } from '../../utils/ProductUtility';

const x = { x: 0, y: 0 };
const y = { x: 0, y: 1 };

const getDefaultVariantForFilters = (variants, selectedFilters = {}) => {
  let minPrice;
  let maxPrice;
  if (isBlank(selectedFilters)) return getInStockVariant(variants) || {};
  let filteredVariants = variants.sort(function (a, b) {
    return parseFloat(a.price.amount) - parseFloat(b.price.amount);
  });
  if (selectedFilters['Price']) {
    selectedFilters['Price'].forEach((priceFilter) => {
      const { price: { min = 0, max = 0 } = {} } = JSON.parse(priceFilter.input);
      if (isBlank(minPrice) || minPrice > parseFloat(min)) {
        minPrice = parseFloat(min);
      }
      if (isBlank(maxPrice) || maxPrice < parseFloat(max)) {
        maxPrice = parseFloat(max);
      }
    });

    filteredVariants = variants.filter(
      (variant) =>
        parseFloat(variant.price?.amount) >= parseFloat(minPrice) &&
        parseFloat(variant.price?.amount) <= parseFloat(maxPrice),
    );
  }
  return findMatchingVariant(selectedFilters, filteredVariants) || getInStockVariant(variants);
};

const getInStockVariant = (variants) => {
  return variants.filter((variant) => variant.availableForSale)[0] || variants[0];
};

function findMatchingVariant(selectedFilter, variants) {
  for (let variant of variants) {
    let matchesAllFilters = true;

    // Loop through each filter key (e.g., 'Color', 'Size')
    for (let filterKey in selectedFilter) {
      let filterOptions = selectedFilter[filterKey];

      if (filterKey === 'Price') break;

      // Check if the variant has an option that matches the filter
      let matchesFilter = filterOptions.some(filter => {
        return variant.selectedOptions.some(option => {
          return option.name === filterKey && option.value === filter.label;
        });
      });

      // If any filter is not matched, set matchesAllFilters to false
      if (!matchesFilter) {
        matchesAllFilters = false;
        break;
      }
    }

    // If all filters are matched, return the variant
    if (matchesAllFilters) {
      return variant;
    }
  }

  // If no variant matches all filters, return null
  return null;
}

const ShopifyProductGrid = (props) => {
  const {
    listData,
    index = '',
    itemData: contentData,
    selectedFilters,
  } = props;
  const {
    size = 'defaultSize',
    options = {},
    id: listId = '',
    name: listName = '',
    content: listContent = '',
  } = listData || {};
  const itemData = {
    ...contentData,
    ...contentData?.shopify_product?.data,
    ...contentData?.shopify_product?.data?.attributes?.data,
  };
  const navigation = useNavigation();
  const {
    title = '',
    variants = {},
    featuredImage,
    options: variantOptions = [],
    id,
    handle,
    image: { src = '' } = {},
    transparent_image_url = '',
    transparent_image_vibrant_colors: {
      vibrant_color: backgroundColor = colors.white,
    } = {},
    available = false,
  } = itemData || {};
  const { id: imageId, url: imageUrl, alt: imageName } = featuredImage || {};
  let {
    title_line_count: titleLineCount,
    hide_brand_name: hideBrandName = '1',
    cta_position_bottom: ctaPositionBottom = '1',
    show_borders_for_list_items = 0,
    disable_card_background_color = '1',
  } = options || {};
  let { featured_image = '' } = itemData;
  let cardContainerStyle = styles[`${size}CardContainer`];
  const imageContainerStyle = styles[`${size}ImageContainer`];
  const defaultVariantSelected =
    getDefaultVariantForFilters(
      variants.nodes || variants,
      selectedFilters,
    ) || {
      ...contentData?.shopify_product?.data?.attributes?.data?.variants?.[0],
    } ||
    {};
  const { title: selectedVariantTitle = '', image: variantImageObject = {} } = defaultVariantSelected || {};
  const { url: variantImage = '' }  = variantImageObject || {};

  const calculateDiscount = (originalPrice = 0, discountedPrice) => {
    const discount =
      ((parseFloat(originalPrice) -
        parseFloat(discountedPrice || originalPrice)) /
        parseFloat(originalPrice)) *
      100;
    return discount === 0 ? null : Math.round(discount);
  };

  const getVariantCount = () => {
    if (isBlank(variantOptions)) return 0;
    let totalCount = 1;
    variantOptions.forEach((option) => {
      totalCount *= option.values.length;
    });
    return totalCount;
  };

  const navigateToShopifyProductDetail = (defaultVariantSelected) => {
    navigation.push('Product', {
      id,
      slug: handle,
      handle,
      itemData,
      selectedVariant: defaultVariantSelected,
    });
  };

  let variantCount = getVariantCount();
  if (selectedVariantTitle === 'Default Title') {
    variantCount = 0;
  }
  const variantText = variantCount > 1 ? 'Sizes' : 'Size';
  let height = cardContainerStyle.height;
  const { nodes: { 0: firstProductItemData = {} } = [] } = listData || {};
  const colorVariants = firstProductItemData.options?.filter((option) => option.name === 'Color')?.[0] || {};
  if (hideBrandName === '1') {
    ctaPositionBottom = '1';
    height -= 22;
  }
  if (parseInt(titleLineCount) === 1) {
    height -= 25;
  }
  if (ctaPositionBottom === '1') {
    height += 30;
  }
  if (isPresent(colorVariants)) {
    height += 37;
  }
  cardContainerStyle = [cardContainerStyle, { height }];

  if (isPresent(transparent_image_url) || show_borders_for_list_items) {
    cardContainerStyle = [cardContainerStyle, { borderColor: colors.border }];
  }

  const compareAtPrice =
    defaultVariantSelected.compareAtPrice?.amount ||
    defaultVariantSelected.compare_at_price;
  const price =
    defaultVariantSelected.price?.amount || defaultVariantSelected.price;

  let mrp = compareAtPrice || price;
  let sp = price;

  if (`${mrp}`.length >= 5 && `${mrp}`.slice(-2) == '00') {
    mrp = `${mrp}`.replace('00', '');
    sp = `${sp}`.replace('00', '');
  }

  const hasVariants =
    variantCount > 0 && selectedVariantTitle !== 'Default Title';
  const { id: defaultVariantId = '' } = defaultVariantSelected || {};
  const skuId = `${defaultVariantId}`.split('/')?.pop();

  const cartContainerStyle =
    ctaPositionBottom === '0'
      ? styles.cartImageContainer
      : styles.cartImageContainerForBottomCta;

  const finalBackgroundColor =
    disable_card_background_color === '1' || isBlank(transparent_image_url)
      ? colors.white
      : backgroundColor || colors.white;
  const gradientArray = [
    Utility.addAlpha(finalBackgroundColor, 0.15),
    Utility.addAlpha(finalBackgroundColor, 0.25),
  ];
  const transparentImageUrl =
    disable_card_background_color !== '1' ? transparent_image_url : '';

  let isInventoryAvailable = isVariantInStockShopifyDirect(defaultVariantSelected);
   
  if (!featured_image.includes('https:')) {
    featured_image = `https:${featured_image}`;
  }

  let productImage = imageUrl || src || featured_image;
  if (isPresent(variantImage)) {
    productImage = variantImage;
  }
  return (
    <ScaleAnimate
      {...props}
      onPress={memoize(
        () => navigateToShopifyProductDetail(defaultVariantSelected),
        () => [defaultVariantSelected.id],
      )}
    >
      <Card style={cardContainerStyle}>
        <View style={imageContainerStyle}>
          <ShopifyProductImageAndRating
            imageUrl={productImage}
            imageId={imageId}
            imageName={imageName}
            size={size}
            height={imageContainerStyle?.height}
            width={cardContainerStyle?.width}
            transparentImageUrl={transparentImageUrl}
          />
          <LinearGradient
            colors={gradientArray}
            style={styles.gradient}
            start={x}
            end={y}
          />
        </View>
        <View style={styles.fullCardDivider} />
        <ShopifyProductDescription
          itemData={itemData}
          name={title}
          // brandName={brandName}
          layout={LAYOUT.GRID}
          titleLineCount={parseInt(titleLineCount)}
          hideBrandName={hideBrandName === '1'}
          mrp={mrp}
          sp={sp}
          discount={calculateDiscount(mrp, sp)}
          listData={listData}
        />
        <View style={cartContainerStyle}>
          <AddToCart
            skuId={skuId}
            layout={LAYOUT.GRID}
            addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
            id={id}
            itemData={itemData}
            listId={listId}
            listName={listName}
            listContent={listContent}
            index={index}
            listData={listData}
            selectedVariant={defaultVariantSelected}
            cta_position_bottom={ctaPositionBottom}
            hasVariants={hasVariants}
            outOfStock={!isInventoryAvailable && !available}
            shopifyDirect
          />
        </View>
      </Card>
      <ProductOtherVariants
        variantsCount={variantCount}
        containerStyle={styles.variantContainerStyle}
        variantText={variantText}
      />
    </ScaleAnimate>
  );
};

ShopifyProductGrid.getComponentHeight = (size) =>
  styles[`${size}CardContainer`]?.height || 200;

export default ShopifyProductGrid;

const styles = StyleSheet.create({
  defaultSizeCardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 4,
    width: getDynamicWidthForGrid(2, 16),
    height: getDynamicWidthForGrid(2, 16) + 116,
    alignSelf: 'center',
  },
  dynamicCardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 4,
    width: getDynamicWidthForGrid(2, 16),
    height: getDynamicWidthForGrid(2, 16) + 116,
    alignSelf: 'center',
  },
  smallCardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 4,
    width: getDynamicWidthForGrid(2, 16),
    height: getDynamicWidthForGrid(2, 16) + 116,
    alignSelf: 'center',
  },
  slimCardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 4,
    width: (getScreenWidth() * 0.67) / 2,
    height: (getScreenWidth() * 0.67) / 2 + 116,
    alignSelf: 'center',
  },
  slimImageContainer: {
    height: (getScreenWidth() * 0.67) / 2,
    resizeMode: 'contain',
    overflow: 'hidden',
  },
  largeCardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    width: getScreenWidth() / 2.15,
    height: ((4 / 3) * getScreenWidth()) / 2.15 + 116,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: 4,
    marginBottom: 6,
    borderRadius: isPresent(Config.LARGE_PRODUCT_CARD_CORNER)
      ? parseInt(Config.LARGE_PRODUCT_CARD_CORNER)
      : 0,
  },
  cartImageContainerForBottomCta: {
    width: '100%',
    marginTop: 8,
  },
  gradient: {
    height: '100%',
    width: '100%',
    borderRadius: 4,
    top: 0,
    position: 'absolute',
    zIndex: -1,
  },
  defaultSizeImageContainer: {
    height: getDynamicWidthForGrid(2, 16),
    width: '100%',
  },
  dynamicImageContainer: {
    height: getDynamicWidthForGrid(2, 16),
    width: '100%',
  },
  smallImageContainer: {
    height: getDynamicWidthForGrid(2, 16),
    width: '100%',
  },
  largeImageContainer: {
    height: ((4 / 3) * getScreenWidth()) / 2.15,
    width: '100%',
  },
  fullCardDivider: {
    height: 1,
    width: '100%',
    backgroundColor: colors.borderColor,
  },
  cartImageContainer: {
    position: 'absolute',
    bottom: 94,
    right: 10,
  },
  variantContainerStyle: {
    position: 'absolute',
    left: 12,
    top: 16,
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 15,
    maxWidth: 84,
    padding: 2,
    backgroundColor: '#ffffff',
  },
});
