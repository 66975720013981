import { Text } from 'react-native';
import React from 'react';
import { ProductDetailStyles } from './styles';
import { isBlank } from '../../utils/BooleanUtility';
import { ShowVariantQuantityOnPills } from '../../config/Constants';

const VariantQuantityText = ({ item = {} }) => {
  const { variant: { total_quantity } = {} } = item;
  if (!ShowVariantQuantityOnPills || !total_quantity || isBlank(total_quantity) || total_quantity >= 10) return null;
  return <Text style={ProductDetailStyles.inventoryCountText}>{total_quantity} left</Text>;
};

export default React.memo(VariantQuantityText);
